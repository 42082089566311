const ROUTES = {
  "/": {
    "name": "HomePage8088edd1E4904e36B1b15ffd140601be",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesD0b5b1fcD7114af7Bab68ae47a893645",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPageBb943482580c43a8B2fdD7d45fbf6fc7",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage9100b6d7832248949a12936c9b39d6f6",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPageD54dc73e1286411dBd7f17d177c4c87c",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage80386313C2f14ead9bd533009d247fec",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages72450035244a4d629c9f626d6446fded",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages7dce09fbF4e64faa81c9A0f57e6de6f4",
    "type": "HTML",
    "key": "message-pages"
  },
  "/polytopia-2024": {
    "name": "Polytopia2024A4d3b27887e24828992d024ae228f018",
    "type": "HTML",
    "key": "polytopia-2024"
  },
  "/polytopia-2024/workshops": {
    "name": "Polytopia2024WorkshopsEdbfdf63E6234dc690dd62f8297be560",
    "type": "HTML",
    "key": "polytopia-2024-workshops"
  },
  "/polytopia-2025": {
    "name": "Polytopia20250d142b59382d480d8e3aC17e76089664",
    "type": "HTML",
    "key": "polytopia-2025"
  },
  "/resources": {
    "name": "ResourcesPage3d3a20f25587499c8e7684f0e506daca",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages4b19b3e488ce41999bb6Fa537dac159e",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;